// prettier-ignore
export default {
  en: {
    'ETag fornecido para o recurso inválido.': 'Resource updated somewhere else. Reload the page and try again.',
    'Error decoding token.': 'Invalid session. Login again.',
    'Login inválido: E-mail e/ou senha incorreto(s)': 'Email or password invalid.',
    'Quantidade de tentativas de login excedida. Tente novamente dentro de alguns minutos.': 'Login tries limit exceeded. Try again in a few minutes.',    
  },
  pt: {
    'ETag fornecido para o recurso inválido.': 'Recurso alterado em outro local. Recarregue a página e tente novamente.',
    'Error decoding token.': 'Sessão inválida. Faça login novamente.',
    'Login inválido: E-mail e/ou senha incorreto(s)': 'Email ou senha inválidos.',
    'Quantidade de tentativas de login excedida. Tente novamente dentro de alguns minutos.': 'Limite de tentativas de login excedido. Tente novamente dentro de alguns minutos.',
  }
}
