<template>
  <div class="version" :title="title">v{{ version }}</div>
</template>

<script>
import Vue from "vue";

const initVersion = (cb) => {
  const _cb = cb || function() {};
  try {
    if (!Vue.config.appInfo && process?.env?.NODE_ENV !== "development") {
      Vue.http
        .get(`/static/build.json?_=${new Date().getTime()}`)
        .then((r) => {
          if (r && r.body) {
            Vue.config.appInfo = r.body;
            _cb(Vue.config.appInfo);
          } else {
            _cb();
          }
        })
        .catch(() => _cb());
    }
  } catch (error) {
    _cb();
  }
};

export { initVersion };

export default {
  name: "Version",
  data() {
    return { ready: false };
  },
  computed: {
    info() {
      return this.ready ? Vue.config.appInfo : null;
    },
    version() {
      return (
        (this.ready ? Vue.config.appInfo.v : "") ||
        Vue.config.packageVersion ||
        "0"
      );
    },
    title() {
      //`version: ${this.info.v}.${this.info.c}\n     date: ${this.info.d}`
      //`version: ${this.info.v}\n    build: ${this.info.c}\n     date: ${this.info.d}`
      return this.ready
        ? `${this.info.v}.${this.info.c}.${this.info.d}.${this.info.b}`
        : this.version;
    }
  },
  created() {
    initVersion((r) => {
      this.ready = r ? true : false;
    });
  }
};
</script>

<style scoped>
.version {
  position: fixed;
  color: #949494;
  text-align: right;
  font-size: 8pt;
  font-family: arial;
  bottom: 0;
  left: 0;
  padding: 5px;
  border-radius: 4px;
}

.version:hover {
  background-color: white;
}
</style>
